import {
  SET_ACTIVE_URL,
  SET_NAVBAR_PROPS,
  SET_SECTION_IN_VIEW_INDEX,
  SET_SUB_MENU,
  SET_LOCATION,
  SET_LANGUAGE,
  SET_LAYOUT_DIRECTION,
  SET_LOADING,
} from "./global.types";

export const setNavbarProps = (payload) => {
  return {
    type: SET_NAVBAR_PROPS,
    payload,
  };
};

export const setSubMenu = (payload) => {
  return {
    type: SET_SUB_MENU,
    payload,
  };
};

export const setLoading = (payload) => {
  return {
    type: SET_LOADING,
    payload,
  };
};
export const setActiveUrl = (payload) => {
  return {
    type: SET_ACTIVE_URL,
    payload,
  };
};
export const setHomeSectionInViewIndex = (payload) => {
  return {
    type: SET_SECTION_IN_VIEW_INDEX,
    payload,
  };
};

export const setLocation = (payload) => {
  return {
    type: SET_LOCATION,
    payload,
  };
};


export const setLanguage = (payload) => {
  return {
    type: SET_LANGUAGE,
    payload,
  };
};

export const setLayoutDirection = (payload) => {
  return {
    type: SET_LAYOUT_DIRECTION,
    payload,
  };
};


