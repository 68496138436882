import { getLanguage, getRegion } from "../utils";
import instance from "./axiosInstance";

// let baseUrl = "192.168.1.31:1337"; // local
let baseUrl = `https://api.athgadlang.com`;
// let baseUrl = `http://localhost:1337`;

// get request
const getRequest = async (endpoint) => {
  try {
    let response = await instance.get(endpoint, {
      params: { _locale: getLanguage(), region: getRegion() },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

// get request without region
const getRequestWithoutRegion = async (endpoint) => {
  try {
    let response = await instance.get(endpoint, {
      params: { _locale: getLanguage()},
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

// post request

const postRequest = async (endpoint, body) => {
  try {
    let response = await instance.post(endpoint, body);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export { getRequest, postRequest, baseUrl, getRequestWithoutRegion };
