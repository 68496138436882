import {
	SET_NAVBAR_PROPS,
	SET_SUB_MENU,
	SET_ACTIVE_URL,
	SET_SECTION_IN_VIEW_INDEX,
	SET_LANGUAGE,
	SET_LOCATION,
	SET_LOADING,
	SET_LAYOUT_DIRECTION,
} from './global.types';

const INITIAL_STATE = {
	location: 'uae',
	language: 'en',
	loading: false,
	navbarProps: {
		backgroundColor: '#ffffff00',
		navbarContentTexture: 'black',
	},
	layoutDirection: 'ltr',
	subMenu: null,
	activeUrl: '',
	homeSectionInViewIndex: 0,
};
const reducer = (state = INITIAL_STATE, action) => {
	if (window.location.href === 'https://wathiqconsultancy.com/') {
		state.location = 'ksa';
    localStorage.setItem('athGadlang',`{"region":"${state.location}"}`)
	} else {
		const urlParams = new URLSearchParams(window.location.search);
		const region = urlParams.get('region');
		if (region) {
      localStorage.setItem('athGadlang',`{"region":"${region}"}`)
			state.location = region;
		} else if (state.location === 'ksa') {
			state.location = 'uae';
      localStorage.setItem('athGadlang',`{"region":"${state.location}"}`)
      
		}
	}

	switch (action.type) {
		case SET_NAVBAR_PROPS: {
			return {
				...state,
				navbarProps: {
					...action.payload,
				},
			};
		}
		case SET_SUB_MENU: {
			return {
				...state,
				subMenu: [...action.payload],
			};
		}
		case SET_LOADING: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case SET_ACTIVE_URL: {
			return {
				...state,
				activeUrl: action.payload,
			};
		}
		case SET_SECTION_IN_VIEW_INDEX: {
			return {
				...state,
				homeSectionInViewIndex: action.payload,
			};
		}
		case SET_LANGUAGE: {
			return {
				...state,
				language: action.payload,
			};
		}
		case SET_LOCATION: {
			return {
				...state,
				location: action.payload,
			};
		}
		case SET_LAYOUT_DIRECTION: {
			return {
				...state,
				layoutDirection: action.payload,
			};
		}

		default:
			return state;
	}
};

export default reducer;
