const arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.72"
    height="8.484"
    viewBox="0 0 12.72 8.484"
  >
    <defs>
      <style>{`.webinarArrrow{fill:#191919;}`}</style>
    </defs>
    <path
      className="webinarArrrow"
      d="M15.994,11.414a.577.577,0,0,0,0,.813l2.682,2.691H8.452a.574.574,0,0,0,0,1.149H18.671l-2.686,2.691a.581.581,0,0,0,0,.813.572.572,0,0,0,.809,0L20.439,15.9h0a.645.645,0,0,0,.119-.181A.548.548,0,0,0,20.6,15.5a.576.576,0,0,0-.163-.4L16.8,11.427A.563.563,0,0,0,15.994,11.414Z"
      transform="translate(-7.882 -11.252)"
    />
  </svg>
);

export { arrow };
