import { motion } from "framer-motion";
import React from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import { arrow as arrowSvg } from "../arrowIcon/index.js";
import { useSelector } from "react-redux";
export const NormalButton = ({
  bgColor,
  text,
  icon,
  txtColor,
  borderColor,
  onClick,
  size,
  disabled = false,
  hoveredBgColor,
  hoveredBorderColor,
  hoveredTextColor,
  customHeight = null,
  borderRadius = 4,
  fontSize = 14,
  arrow = false,
}) => {
  const { t } = useTranslation();
  const layoutDirection = useSelector((state) => state.global.layoutDirection);

  const getClassName = () => {
    if (!size) return "customBtn largeBtn";
    if (size === "xs") return "customBtn xSmallBtn";
    if (size === "md") return "customBtn mediumBtn";
    if (size === "lg") return "customBtn largeBtn";
  };

  const btnStyle = {
    color: txtColor || "white",
    backgroundColor: bgColor,
    pointerEvents: disabled ? "none" : "auto",
    borderRadius,
    paddingTop: getClassName() === "customBtn mediumBtn" ? 7 : 5,
    height: customHeight || 45,
    fontSize,
  };

  const camalize = (str) => {
    return str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  };

  // const hoveredProps = {
  //   backgroundColor: hoveredBgColor,
  //   color: hoveredTextColor,
  // };

  const hoveredBtnVariants = {
    hover: {
      backgroundColor: hoveredBgColor,
      color: hoveredTextColor,
    },
  };

  const hoveredTextVariants = {
    hover: {
      color: hoveredTextColor,
    },
  };

  return (
    <motion.button
      onClick={onClick}
      whileHover={"hover"}
      variants={hoveredBtnVariants}
      ref={(el) =>
        el &&
        borderColor &&
        el.style.setProperty(
          "border",
          `1px solid ${borderColor || ""}`,
          "important"
        )
      }
      className={getClassName()}
      style={btnStyle}
    >
      {icon && (
        <img
          alt=""
          style={{
            marginRight: layoutDirection === "ltr" ? 8 : 0,
            marginLeft: layoutDirection === "rtl" ? 8 : 0,
            marginBottom: getClassName() === "customBtn mediumBtn" ? 6 : null,
          }}
          src={icon}
        />
      )}{" "}
      <motion.span
        variants={hoveredTextVariants}
        style={{ color: btnStyle?.color, textTransform: "capitalize" }}
      >
        {t(`${camalize(text)}`)}
      </motion.span>
      {arrow && <span className="normal_btn_arrow">{arrowSvg()}</span>}
    </motion.button>
  );
};
