import { motion } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./index.css";

export const IconButton = ({
  icon,
  txtColor = `var(--secondary-color)`
,
  bgColor = "#FFFFFF",
  txt = "",
  size = "",
  additionalProps = {},
  borderColor = "",
  fontSize = "16-Regular",
  onClick,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const layoutDirection = useSelector((state) => state.global.layoutDirection);

  const camalize = (str) => {
    return str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  };
  const btnProps = {
    borderRadius: 4,
    color: txtColor,
    backgroundColor: bgColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // width: "100%",
    border: 0,
    // button responsive padding
    padding: `0 ${
      size === "sm" ? "0.7rem" : size === "md" ? "1.5rem" : "2.5rem"
    }`,
    // maxWidth : 'fit-content'
  };

  const getFontSize = () => {
    switch (fontSize) {
      case "16-Regular":
        return "font16_Regular";
      case "14-Regular":
        return "font14_Regular";
      default:
        break;
    }
  };

  const getBorder = () => {
    switch (borderColor) {
      case "white":
        return "border_white";

      case "blue":
        return "border_blue";

      case "red":
        return "border_red";

      default:
        break;
    }
  };

  return (
    <div
      className={getBorder()}
      onClick={onClick}
      style={{
        height: "40px",
        maxWidth: "fit-content",
        borderRadius: 4,
        borderColor: "",
        display: "flex",
        flexShrink: 0,
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.5 : 1,
        ...additionalProps,
      }}
    >
      <motion.button whileHover={{ x: 5, y: 5 }} style={{ ...btnProps }}>
        <p
          className={getFontSize()}
          style={{ marginTop: 5, textTransform: "capitalize" }}
        >
          {t(`${camalize(txt)}`)}
        </p>
        {icon && (
          <img
            src={icon}
            style={{
              marginLeft: layoutDirection === "ltr" ? 10 : 0,
              marginRight: layoutDirection === "rtl" ? 10 : 0,
            }}
            alt=""
          />
        )}
      </motion.button>
    </div>
  );
};
