import { AnimatePresence, motion, useAnimation } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import COMPANY_LOGO_TEXT from "../../assets/navbar_logo/logo_text_original.svg";
import WATHIQ_LOGO from "../../assets/wathiq-logo.png";
import {
  setActiveUrl,
  setHomeSectionInViewIndex,
  setNavbarProps,
} from "../../redux/global/global.actions";
import useWindowDimensions from "../dimensions";
import { useDimensions } from "../specificDimensions/index";
import { useOnClickOutside } from "../useOutsideHook";
import usePrevious from "../usePrevious";
import "./index.css";
import { MenuToggle } from "./menuToggle";
import { MobileSidebar } from "./mobileView";
import NavbarActions from "./navbarActions";
import { Navigation } from "./navigation/index";
import { SearchBar } from "./navigation/searchBar";

export const NavBar = () => {
  const [isOpen, toggleOpen] = React.useState(false);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const [isSearchbarVisible, setIsSearchbarVisible] = React.useState(false);
  const global = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const prevBgColor = usePrevious(global.navbarProps.backgroundColor);
  const { width: fullWidth } = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();
  const controls = useAnimation();
  const isScrollTop = useRef();

  const previousScrollValue = useRef();
  const [isPageTop, setIsPageTop] = React.useState(true);

  useOnClickOutside(containerRef, () => toggleOpen(false));

  const currentUrl = React.useRef(location.pathname);
  const setMyState = (data) => {
    currentUrl.current = data;
  };

  React.useEffect(() => {
    attachScrollListenerForNavbarColor();
    window.addEventListener("scroll", attachScrollListenerForNavbarColor);
    return () =>
      window.removeEventListener("scroll", attachScrollListenerForNavbarColor);
  }, []);

  React.useEffect(() => {
    if (isPageTop) {
      previousScrollValue.current = "hover";
      controls.start("hover");
      isScrollTop.current = true;
      location.pathname !== "/" &&
        dispatch(
          setNavbarProps({
            backgroundColor: "#ffff",
            navbarContentTexture: "black",
          })
        );
    } else {
      previousScrollValue.current === "hover" && controls.start("rest");
      previousScrollValue.current = "rest";
      isScrollTop.current = false;
      dispatch(
        setNavbarProps({
          backgroundColor: "#ffff",
          navbarContentTexture: "black",
        })
      );
    }
  }, [isPageTop]);

  const attachScrollListenerForNavbarColor = () => {
    if (window.scrollY > 0) {
      setIsPageTop(false);
    } else {
      setIsPageTop(true);
    }
  };

  React.useEffect(() => {
    setMyState(location.pathname);
    setIsPageTop(true);
    location.pathname && dispatch(setActiveUrl(location.pathname));
    location.pathname !== "/" &&
      dispatch(
        setNavbarProps({
          backgroundColor: "#ffff",
          navbarContentTexture: "black",
        })
      );
  }, [location.pathname]);

  const sidebarVariant = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: () => {
      let closedClipPath = `circle(0px at ${fullWidth * 0.05 + 12}px 40px)`;
      return {
        clipPath: closedClipPath,
        transition: {
          delay: 0.5,
          type: "spring",
          stiffness: 400,
          damping: 40,
        },
      };
    },
  };

  let logoMotion = {
    rest: {
      x: 0,
      transition: {
        duration: 0.5,
        type: "tween",
        ease: "easeIn",
      },
    },
    hover: (direction) => ({
      x: direction === "ltr" ? -55 : 55,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut",
      },
    }),
  };

  const textMotion = {
    rest: () => ({
      x: -20,
      opacity: 0,
      ease: "easeOut",
      transition: {
        duration: 0.5,
      },
      type: "tween",
    }),
    hover: (texture) => {
      return {
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.4,
          type: "tween",
          ease: "easeInOut",
        },
      };
    },
  };

  const handleLogoClick = () => {
    if (location.pathname !== "/") {
      dispatch(setHomeSectionInViewIndex(0));
      history.push("/");
    } else {
      dispatch(setHomeSectionInViewIndex(0));
    }
  };

  return (
    <>
      <AnimatePresence>
        {isSearchbarVisible && (
          <SearchBar onHideSearchbar={() => setIsSearchbarVisible(false)} />
        )}
      </AnimatePresence>
      <motion.div
        whileHover={{ backgroundColor: "#ffff" }}
        initial={{ backgroundColor: prevBgColor }}
        style={{
          boxShadow:
            global.navbarProps.backgroundColor === "#ffff"
              ? "0px 3px 6px #0000000F"
              : null,
        }}
        animate={{
          backgroundColor: global.navbarProps.backgroundColor,
        }}
        className="navbar_container"
      >
        <motion.div
          initial="rest"
          onMouseEnter={() => controls.start("hover")}
          onMouseLeave={() =>
            isScrollTop.current === false && controls.start("rest")
          }
          animate={controls}
        >
          <motion.div
            className="website_complete_logo"
            onClick={handleLogoClick}
            key={global.layoutDirection}
          >
            {global.location === "ksa" ? (
              <img src={WATHIQ_LOGO} alt="" className="wathiq_logo" />
            ) : (
              <>
                {ag(
                  logoMotion,
                  global.navbarProps.navbarContentTexture,
                  global.layoutDirection
                )}
                <motion.img
                  src={COMPANY_LOGO_TEXT}
                  style={{ position: "absolute", width: 120, height: 60 }}
                  variants={textMotion}
                  custom={global.navbarProps.navbarContentTexture}
                />
              </>
            )}
          </motion.div>
          <NavbarActions onSearchBtnClick={() => setIsSearchbarVisible(true)} />
        </motion.div>
      </motion.div>
      {fullWidth > 450 ? (
        <motion.nav
          initial={false}
          animate={isOpen ? "open" : "closed"}
          className={isOpen ? "navbar_menu" : "navbar_menu closed_nav"}
          custom={height}
          ref={containerRef}
        >
          <motion.div className="background" variants={sidebarVariant} />
          <Navigation
            isOpen={isOpen}
            subMenu={global.subMenu}
            onLinkClick={() => toggleOpen(false)}
          />
          <MenuToggle
            hamBurgerColor={
              global.navbarProps.navbarContentTexture === "black"
                ? "black"
                : "white"
            }
            toggleValue={isOpen}
            toggle={() => toggleOpen((prevValue) => !prevValue)}
          />
        </motion.nav>
      ) : (
        // <WebViewNavbar/>
        <>
          <MenuToggle
            hamBurgerColor={
              global.navbarProps.navbarContentTexture === "black"
                ? "black"
                : "white"
            }
            toggleValue={isOpen}
            toggle={() => toggleOpen((prevValue) => !prevValue)}
          />
          <MobileSidebar
            texture={global.navbarProps.navbarContentTexture}
            subMenu={global.subMenu}
            isOpen={isOpen}
            onLinkClick={() => toggleOpen(false)}
          />
        </>
      )}
    </>
  );
};

const ag = (variant, navbarTexture = "", direction) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="49.708"
    height="52.01"
    custom={direction}
    style={{ cursor: "pointer" }}
    viewBox="0 0 49.708 52.01"
    variants={variant}
  >
    <motion.g
      id="Final_Logo-04_1_"
      data-name="Final Logo-04 (1)"
      transform="translate(-7.267 -10.082)"
    >
      <motion.path
        id="Path_51079"
        data-name="Path 51079"
        d="M67.9,26.385,50.965,12.344a9.651,9.651,0,0,0-11.319-.8,7.483,7.483,0,0,0-2.79,9.466l.559,1.2a24.918,24.918,0,0,0,6.82,8.975h0a6.112,6.112,0,0,1,0,9.412l-2.114,1.749a24.116,24.116,0,0,0-6.076,7.6v.063a7.857,7.857,0,0,0-.329,6.514,9.051,9.051,0,0,0,14.127,3.489L67.932,45.02A12.1,12.1,0,0,0,67.9,26.385Z"
        transform="translate(-15.321)"
        fill="#8e8e8e"
        animate={{
          fill: navbarTexture === "black" ? "#C9C9C9" : "#8e8e8e",
        }}
      />
      <motion.path
        id="Path_51094"
        data-name="Path 51094"
        d="M30.326-29.573a17.66,17.66,0,0,1-2.431.818,11.479,11.479,0,0,1-2.969.374,8.152,8.152,0,0,1-4.231-.853,4.276,4.276,0,0,1-1.847-2.443h-.14a15.328,15.328,0,0,1-1.531,1.356,8.788,8.788,0,0,1-1.578.958,11.224,11.224,0,0,1-2.116.76,12.74,12.74,0,0,1-2.84.245,6.982,6.982,0,0,1-4.629-1.6A5.253,5.253,0,0,1,4.1-34.2a7.034,7.034,0,0,1,.421-2.583A5.857,5.857,0,0,1,5.71-38.666a6.2,6.2,0,0,1,1.625-1.239,18.09,18.09,0,0,1,2.022-.912q2.1-.771,5.049-1.6a28.526,28.526,0,0,0,4.3-1.484v-2.244q0-.584-.094-1.543a4.99,4.99,0,0,0-.4-1.613,3.083,3.083,0,0,0-1.029-1.262,3.05,3.05,0,0,0-1.847-.514,4.418,4.418,0,0,0-1.718.292,5.746,5.746,0,0,0-1.11.6,9.151,9.151,0,0,0,.4,1.426,5.706,5.706,0,0,1,.327,1.847,3.11,3.11,0,0,1-.21.994,2.214,2.214,0,0,1-.608.947,4.035,4.035,0,0,1-1.2.725A6.056,6.056,0,0,1,9.17-44,4.18,4.18,0,0,1,6.2-44.943,3.233,3.233,0,0,1,5.2-47.409a3.243,3.243,0,0,1,1.04-2.349,8.508,8.508,0,0,1,2.536-1.718,14.551,14.551,0,0,1,3.39-1.087,18.092,18.092,0,0,1,3.553-.386,29.715,29.715,0,0,1,4.161.269,9.83,9.83,0,0,1,3.413,1.11,5.927,5.927,0,0,1,2.3,2.373,8.4,8.4,0,0,1,.853,4.056q0,2.642-.105,6.265t-.105,4.769a3.637,3.637,0,0,0,.316,1.66,2.01,2.01,0,0,0,.97.912,3.451,3.451,0,0,0,1.251.222q.853.035,1.555.035ZM18.707-41.752q-1.309.444-2.595,1.017a10.357,10.357,0,0,0-2.1,1.2,4.529,4.529,0,0,0-1.356,1.671,5.457,5.457,0,0,0-.491,2.4,3.59,3.59,0,0,0,.83,2.642A2.8,2.8,0,0,0,15.037-32a3.888,3.888,0,0,0,2.116-.526,6.091,6.091,0,0,0,1.461-1.3Z"
        transform="translate(3.17 76.662)"
        animate={{
          fill: navbarTexture === "black" ? "#143557" : "#fff",
        }}
      />
      <motion.path
        id="Path_51080"
        data-name="Path 51080"
        d="M51,52.86v-.266c0-7.307,5.36-12.234,12.567-12.234,5.666,0,10.692,2.578,11.161,8.812H67.944c-.338-2.254-1.776-3.417-4.327-3.417-3.385,0-5.409,2.614-5.409,6.937v.266c0,3.89,1.442,6.906,5.6,6.906,3.349,0,4.625-1.708,4.76-3.606H64.072V51.467H75.341v2.948c0,6.1-3.989,10.692-11.72,10.692C55.44,65.093,51,60.031,51,52.86Z"
        transform="translate(-24.02 -16.63)"
        fill="#a71f25"
      />
    </motion.g>
  </motion.svg>
);

// const pathTransition = { duration: 2, yoyo: Infinity, ease: "easeInOut" };
