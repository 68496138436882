import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN_TRANSLATIONS from "./locales/en/translations.json";
import AR_TRANSLATIONS from "./locales/ar/translations.json";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      translations: EN_TRANSLATIONS,
    },
    ar: {
      translations: AR_TRANSLATIONS,
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "ar"];

export default i18n;
