import * as React from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const MenuItem = ({
  name,
  onHover,
  isActive,
  isMenuExist,
  onClick,
  layoutDirection,
}) => {
  return (
    <motion.li
      onMouseEnter={onHover}
      variants={variants}
      onClick={onClick}
      style={{
        textTransform: "capitalize",
        borderRight:
          layoutDirection === "ltr"
            ? isActive
              ? "3px solid  var(--primary-color)"
              : null
            : null,
        borderLeft:
          layoutDirection === "rtl"
            ? isActive
              ? "3px solid  var(--primary-color)"
              : null
            : null,
      }}
      animate={{
        color: isActive ?  `var(--primary-color)` : "#CACACA",
      }}
      whileTap={{ scale: 0.95 }}
    >
      {name}
    </motion.li>
  );
};
