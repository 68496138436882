import { Box, Divider, Tab, Tabs } from "@material-ui/core";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ARROW_LEFT from "../../../../assets/back.png";
import ARROW_RIGHT from "../../../../assets/sub-menu-arrow-right.svg";
import { generateKey } from "../../../../utils";
import useWindowDimensions from "../../../dimensions";
import { MenuItem } from "../../common";
import NavbarActions from "../../navbarActions";
import "./index.css";

export const SubMenuView = ({ props, onLinkClick }) => {
  const [subLevels, setSubLevels] = useState([]);

  const [activeParentName, setActiveParentName] = useState("");
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [activeParent, setActiveParent] = useState({});
  const layoutDirection = useSelector((state) => state.global.layoutDirection);
 
  useEffect(() => {
    props.name !== "Services" && setSubLevels([]);
  }, [props]);

  useEffect(() => {
    subLevels.length === 0 && setActiveParentName("");
  }, [subLevels]);
 
  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.7)",
        position: "fixed",
        left: "300px",
        width: "100%",
        height: "100%",
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          // width: "60vw",
          opacity: 1,
          transition: {
            opacity: {
              delay: 0.5,
            },
            // width: {
            //   duration: 0.8,
            // },
          },
        }}
        exit={{
          // width: 0,
          opacity: 0,
          transition: {
            opacity: {
              delay: 0.3,
            },
            // width: {
            //   duration: 1,
            // },
          },
        }}
        className="sub_menu_container"
      >
        <NavbarActions parent="sidebar" />
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={props.name}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            {width < 1000 || props.to !== "/services" ? (
              <motion.h2
                whileHover={{
                  color: `var(--secondary-color)`,
                }}
                style={{
                  textTransform: "capitalize",
                  cursor: "pointer",
                  width: "fit-content",
                }}
                className="font44_Regular"
                onClick={() => {
                  onLinkClick();
                  history.push(props.to);
                }}
              >
                {props.name}
              </motion.h2>
            ) : (
              <TabMenu
                props={props}
                onSelect={(item) => {
                  setActiveParent(item);
                  if (item.isMenuExist) {
                    setActiveParentName(item.title);
                    setSubLevels([item.subMenu]);
                  } else setSubLevels([]);
                }}
              />
            )}
            <Divider style={{ marginBottom: 20 }} />
            {width > 1000 ? (
              <motion.div className="submenu_content">
                <motion.div
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {props.to !== "/services" &&
                    props.subMenu.map((item, mainIndex) => (
                      <Link
                        onClick={onLinkClick}
                        to={{
                          pathname: item.to,
                          state: {
                            level: item.level,
                            id: item.id,
                            categoryName: item?.title,
                          },
                        }}
                        style={{
                          fontWeight:
                            activeParentName === item.title ? 700 : 400,
                        }}
                        key={generateKey(item.title)}
                        onMouseEnter={() => {
                          if (item.isMenuExist) {
                            setActiveParentName(item.title);
                            setSubLevels([item.subMenu]);
                          } else setSubLevels([]);
                        }}
                      >
                        {item.title}{" "}
                        {item.isMenuExist && (
                          <img
                            width={10}
                            height={10}
                            src={
                              layoutDirection === "ltr"
                                ? ARROW_RIGHT
                                : ARROW_LEFT
                            }
                            alt=""
                          />
                        )}
                      </Link>
                    ))}
                </motion.div>
                <AnimatePresence>
                  {subLevels.map((subLevel, index) => {
                    return (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft:
                            layoutDirection === "ltr"
                              ? index === 0
                                ? 0
                                : "5%"
                              : 0,
                          marginRight:
                            layoutDirection === "rtl"
                              ? index === 0
                                ? 0
                                : "5%"
                              : 0,
                        }}
                      >
                        {subLevel.map((item) => (
                          <Link
                            key={generateKey(item.title)}
                            onClick={onLinkClick}
                            to={{
                              pathname: item.to,
                              state: {
                                level: item.level,
                                id: item.id,
                                categoryName: item.title,
                              },
                            }}
                            style={{
                              fontWeight: subLevels.find(
                                (obj) => obj.title === item.title
                              )
                                ? 700
                                : 400,
                              display: "inline-flex",
                              alignItems: "baseline",
                              justifyContent: "flex-start",
                            }}
                            onMouseEnter={() => {
                              let clonedSubLevels = [...subLevels];
                              if (item.isMenuExist) {
                                clonedSubLevels[index + 1] = item.subMenu;
                              } else {
                                clonedSubLevels.splice(
                                  index + 1,
                                  clonedSubLevels.length
                                );
                              }

                              setSubLevels(
                                clonedSubLevels.slice(0, item?.level)
                              );
                            }}
                          >
                            <span style={{ width: "max-content" }}>
                              {item.title}
                            </span>
                            {item.isMenuExist && (
                              <img
                                width={10}
                                height={10}
                                src={
                                  layoutDirection === "ltr"
                                    ? ARROW_RIGHT
                                    : ARROW_LEFT
                                }
                                alt=""
                                style={{ marginLeft: 5 }}
                              />
                            )}
                          </Link>
                        ))}
                      </motion.div>
                    );
                  })}
                </AnimatePresence>
              </motion.div>
            ) : (
              <div className="tablet_view_submenu_content">
                {props?.subMenu &&
                  props?.subMenu.map((item, key) => {
                    item.onLinkClick = onLinkClick;
                    return <MenuItem key={generateKey(key)} item={item} />;
                  })}
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

const TabMenu = ({ props, onSelect }) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      onSelect(props.subMenu[0]);
    }, 300);
  }, []);

  return (
    // <Box>
    //   <Tabs
    //     indicatorColor="primary"
    //     value={activeTab}
    //     onChange={handleChange}
    //     aria-label="menu"
    //     // variant="scrollable"
    //   >
    //     {props?.subMenu.map((item, index) => (
    //       <Tab
    //         value={index}
    //         key={item?.title}
    //         label={item?.title}
    //         onClick={() => onSelect(item)}
    //         style={{
    //           fontSize: 14,
    //           textTransform: "capitalize",
    //           color: index === activeTab ? `var(--primary-color)` : "#2b2b2b",
    //           fontWeight: index === activeTab ? 800 : 500,
    //         }}
    //       />
    //     ))}
    //   </Tabs>
    // </Box>
    <div className="tabs_wrapper">
      {props?.subMenu.map((item, index) => (
        <div
          key={item?.title}
          className={`single_tab ${activeTab === index ? "active" : ""}`}
          onClick={() => {
            onSelect(item);
            setActiveTab(index);
          }}
        >
          {item?.title}
        </div>
      ))}
    </div>
  );
};
