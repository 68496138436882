import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MenuItem } from "./menuItems";
import { SubMenuView } from "./subMenuView";

const variants = {
  open: {
    transition: { delay: 2, staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      when: "afterChildren",
    },
  },
};

export const Navigation = ({ isOpen, subMenu, onLinkClick }) => {
  const [activeSubMenuIndex, setActiveSubMenuIndex] = React.useState(0);
  const history = useHistory();
  const layoutDirection = useSelector((state) => state.global.layoutDirection);

  return (
    <AnimatePresence>
      {isOpen && subMenu && (
        <>
          <motion.ul variants={variants} exit={"closed"}>
            {subMenu.map((item, i) => {
              item.onClick = () => {
                if(item.name ==='Careers'){
                  window.open('https://careers.athgadlang.com/','_blank');
                  
                }else{
                  history.push(item.to);
                  onLinkClick();
                }
              };

              return (
                <MenuItem
                  isActive={i === activeSubMenuIndex}
                  onHover={() => setActiveSubMenuIndex(i)}
                  layoutDirection={layoutDirection}
                  {...item}
                  key={i}
                />
              );
            })}
          </motion.ul>

          {subMenu[activeSubMenuIndex] &&
            subMenu[activeSubMenuIndex].isMenuExist && (
              <SubMenuView
                props={subMenu[activeSubMenuIndex]}
                onLinkClick={onLinkClick}
              />
            )}
        </>
      )}
    </AnimatePresence>
  );
};
