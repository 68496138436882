import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import BACK_ICON from "../../../assets/icon_back.svg";
import ARROW_RIGHT from "../../../assets/sub-menu-arrow-right.svg";
import useWindowDimensions from "../../../service/windowDimensions";
import { MenuItem } from "../common";
import NavbarActions from "../navbarActions";
import "./index.css";



export const MobileSidebar = ({ subMenu, onLinkClick, isOpen }) => {
  const [activeScreen, setActiveScreen] = useState("list");
  const [activeSubMenuIndex, setActiveSubMenuIndex] = React.useState(0);

  const history = useHistory();
  const { height, width } = useWindowDimensions();
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="mobile_sidebar"
          initial={{ x: "-100vw" }}
          animate={{
            x: 0,
            transition: {
              easings: "easeInOut",
              when: "beforeChildren",
            },
          }}
          exit={{
            x: "-100vw",
            transition: {
              easings: "easeInOut",
            },
          }}
        >
          <NavbarActions parent="mobileSidebar" />
          <AnimatePresence exitBeforeEnter>
            {activeScreen === "list" && (
              <motion.div
               
                className="main_list_container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ x: "-100vw" }}
              >
                {subMenu &&
                  subMenu.map(({ name, isMenuExist, to }, index) => (
                    <div
                    key={index}
                      onClick={() => {
                        if(width < 800){
                          history.push('/'+name)
                        }
                        if (isMenuExist) {
                          setActiveSubMenuIndex(index);
                          setActiveScreen("details");
                        } else {
                          history.push(to);
                          onLinkClick();
                        }
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 15,
                        marginBottom: 5,
                      }}
                    >
                      <span>{name}</span>
                      {isMenuExist && <img src={ARROW_RIGHT} alt="" />}
                    </div>
                  ))}
              </motion.div>
            )}
            {activeScreen === "details" && (
              <>
                <div style={{ marginTop: 5 }}>
                  <span
                    onClick={() => setActiveScreen("list")}

                    style={{ color: `#969595` }}
                  >
                    <img src={BACK_ICON} style={{ marginRight: 5 }} alt="" />
                    Back
                  </span>
                  <h2
                    className="font32_Bold"
                    onClick={() => {
                      history.push(subMenu[activeSubMenuIndex].to);
                      onLinkClick();
                    }}
                    style={{ marginTop: 20 }}
                  >
                    {subMenu[activeSubMenuIndex].name}
                  </h2>
                </div>
                <motion.div
                  className="detail_container"
                  initial={{ opacity: 0, x: "100vw" }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: "100vw" }}
                >
                  <div>
                    {subMenu[activeSubMenuIndex].subMenu &&
                      subMenu[activeSubMenuIndex].subMenu.map( (item, key) => {
                        item.onLinkClick = onLinkClick;
                        
                        return <MenuItem item={item} id={key} key={key} />;
                      })}
                  </div>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
