const truncateString = (str, num) => {
  if (str?.length > num) {
    return str?.slice(0, num) + "...";
  } else {
    return str;
  }
};

const getLast6Years = () => {
  let years = [];
  let currentYear = new Date().getFullYear();
  for (let i = 0; i < 6; i++) {
    years = [...years, (currentYear - i).toString()];
  }
  return years;
};

const hasChildren = (item) => {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
};
const setPageDirection = (language) => {
  const rtlLanguages = ["ar"];
  const dir = rtlLanguages.includes(language) ? "rtl" : "ltr";
  document.documentElement.dir = dir;
};

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// convert mins to time

let timeConvert = (n) => {
  n = parseInt(n);
  if (n || n === 0) {
    let num = n,
      hours = num / 60,
      rhours = Math.floor(hours),
      minutes = (hours - rhours) * 60,
      rminutes = Math.round(minutes);

    if (rhours > 0 && rminutes > 0) {
      return rhours + "h " + rminutes + `m`;
    }

    if (rhours > 0 && rminutes === 0) {
      return rhours + "h";
    }

    if (rhours === 0 && rminutes > 0) {
      return rminutes + "m";
    }

    if (!n) {
      return 0 + "m";
    }
  } else {
    return n;
  }
};

// genrating unique keys

const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
};

// getting language from local storage

const getLanguage = () => {
  let storage = localStorage.getItem("athGadlang")
    ? JSON.parse(localStorage.getItem("athGadlang"))
    : "";
  let { language } = storage;
  return language ? language : "en";
};

// get region from local storage

const getRegion = () => {
  let storage = localStorage.getItem("athGadlang")
    ? JSON.parse(localStorage.getItem("athGadlang"))
    : "";
  let { region } = storage;
  return region ? region : "uae";
};

const languages = [
  { label: "English", value: "en" },
  { label: "عربی", value: "ar" },
];

const locations = [
  { label: "UAE", value: "uae" },
  { label: "Bahrain", value: "bh" },
  { label: "KSA", value: "ksa" },
  { label: "Oman", value: "om" },
];

const locationsAr = [
  { label: "الإمارات العربية المتحدة", value: "uae" },
  { label: "البحرين", value: "bh" },
  { label: "الكويت", value: "ksa" },
  { label: "العُمان", value: "om" },
];

//camelize

const camalize = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export {
  truncateString,
  getLast6Years,
  hasChildren,
  setPageDirection,
  validateEmail,
  timeConvert,
  generateKey,
  getLanguage,
  getRegion,
  camalize,
  languages,
  locations,
  locationsAr,
};
