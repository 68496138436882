import { AnimatePresence } from "framer-motion";
import React, { Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { AnimateWrapper } from "../components/animatePages";
import { NavBar } from "../components/navbar";
import { PageSkeleton } from "../components/pageSkeleton";
import ScrollToTop from "../components/scrollToTop";
import Routes from "./CONSTANTS";
const NotFound = React.lazy(() => import("../components/notFound"));

export const RouterConfig = () => {
  const location = useLocation();

  return (
    <>
      <NavBar />
      <Suspense fallback={<PageSkeleton />}>
        <ScrollToTop />
        <AnimatePresence>
          <Switch location={location} key={location.pathname}>
            {Routes.map(({ Component, exact, path }, index) => (
              <Route
                exact={exact}
                path={path}
                key={`${path}-${index}`}
                render={(props) => <Component {...props} />}
              />
            ))}

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </AnimatePresence>
      </Suspense>
    </>
  );
};
