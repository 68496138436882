import axios from "axios";
// export let baseUrl = `http://192.168.1.31:1337`;
export let baseUrl = `https://api.athgadlang.com`;
// export let baseUrl = `http://localhost:1337`;

const instance = axios.create({ baseURL: baseUrl });
instance.interceptors.request.use((config) => {
  config.params = {
    // add your default ones

    // spread the request's params
    ...config.params,
  };
  return config;
});
export default instance;
