import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './App.css';
import { NotificationSetup } from './components/NotificationSetup';
import './globalStyle/index.css';
import { RouterConfig } from './navigation/RouterConfig';
import { setSubMenu } from './redux/global/global.actions';
import { getRequest } from './service/apiClient';
import { getMenu } from './service/constants';
import './i18n/config';
import Footer from './components/footer';
import { useTranslation } from 'react-i18next';
import { setLocation } from './redux/global/global.actions';
function App() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { layoutDirection, language, location, loading } = useSelector((state) => state.global);

	useEffect(() => {
		const init = async () => {
			try {
				let { data, status } = await getRequest(getMenu);
				if (status === 200) {
					data.unshift({
						name: t('home'),
						to: '/',
						isMenuExist: false,
					});
					dispatch(setSubMenu(data));
				}
			} catch (error) {
				console.error(error);
			}
		};
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language, location]);

	// setting language

	// setting language
	useEffect(() => {
		const timer = window.setTimeout(() => {
			if (window.location.href === 'https://wathiqconsultancy.com/') {
				setLocation('ksa');
				let elements = Array.prototype.slice.call(document.querySelectorAll('p, h3'));
				elements.forEach(function (el) {
					// Do the replace on the element
					el.textContent = el.textContent
						.replaceAll(/\bGadlang\b/gi, 'Wathiq')
						// eslint-disable-next-line no-useless-escape
						.replaceAll(/\athGADLANG\b/gi, 'Wathiq')
						// eslint-disable-next-line no-useless-escape
						.replaceAll(/\GADLANG\b/gi, 'Wathiq');
				});

				// window.location.href = 'https://wathiqconsultancy.com/'

				const script = document.createElement('script');
				script.src = 'https://fw-cdn.com/3664689/3132684.js';
				script.id = 'fw-script';
				script.setAttribute('chat', true);
				script.setAttribute('widgetId', '414d37ac-c647-482f-be9d-3a5fb0be3108');
				document.body.appendChild(script);
				document.body.setAttribute('data-theme', 'ksa');
			} else {
				let elements = Array.prototype.slice.call(document.querySelectorAll('p, h3'));
				elements.forEach(function (el) {
					// Do the replace on the element
					el.textContent = el.textContent
						.replaceAll(/\bWathiq\b/gi, 'GADLANG')
						.replaceAll(/\Wathiq\b/gi, 'athGADLANG')
						.replaceAll(/\Wathiq\b/gi, 'GADLANG');
				});
				const script = document.createElement('script');
				script.src = 'https://fw-cdn.com/3664689/3132684.js';
				script.id = 'fw-script';
				script.setAttribute('chat', true);
				document.body.appendChild(script);
				document.body.setAttribute('data-theme', '');
				// window.location.href = 'https://athgadlang.com/'
			}
		}, 1500);

		return () => {
			window.clearInterval(timer);
		};
	}, [location, loading]);

	return (
		<>
			{/* <PageLoader /> */}
			<div className={`${layoutDirection === 'rtl' ? 'global_rtl' : ''} ${language === 'ar' ? 'lang_ar' : ''} page_container`}>
				<NotificationSetup />
				<Router>
					<RouterConfig />
					<Footer />
				</Router>
			</div>
		</>
	);
}

export default App;
