import React, { useState,useEffect } from "react";
import { NormalButton } from "../normalButton";
import "./index.css";
import INSTAGRAM_ICON from "../../assets/footer_icons/instagram.svg";
import TWIITER_ICON from "../../assets/footer_icons/twitter.svg";
import LINKEDIN_ICON from "../../assets/footer_icons/linkedin.svg";
import FACEBOOK_ICON from "../../assets/footer_icons/facebook.svg";
import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../utils";
import { toast } from "react-toastify";
import { postRequest } from "../../service/apiClient";
import { postSubscriber } from "../../service/constants";
// import { useHistory } from "react-router-dom";
// import {  motion } from "framer-motion";
import { getRequest } from "../../service/apiClient";

// import COMPANY_LOGO_TEXT from "../../assets/navbar_logo/logo_text_original.svg";
import WATHIQ_LOGO from "../../assets/wathiq-logo-footer.png";
import ATH_LOGO from "../../assets/ATH_LOGO.png";

import { useSelector } from "react-redux";
import { contactDetail } from "../../service/constants";
const Footer = ({width}) => {
  // const location = useLocation();
  // console.log(location);
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
    const [detail, setDetail] = useState();

  // const history = useHistory();
  const global = useSelector((state) => state.global);
useEffect(() => {
  const getDetail = async () => {
    let data = await getRequest(contactDetail);
    setDetail(data?.data[0]);
  };

  getDetail();
}, []);
  
  // let logoMotion = {
  //   rest: {
  //     x: 0,
  //     transition: {
  //       duration: 0.5,
  //       type: "tween",
  //       ease: "easeIn",
  //     },
  //   },
  //   hover: (direction) => ({
  //     x: direction === "ltr" ? -55 : 55,
  //     transition: {
  //       duration: 0.4,
  //       type: "tween",
  //       ease: "easeOut",
  //     },
  //   }),
  // };



  const handleEmailSubmit = async () => {
    if (!email) {
      return;
    }

    if (!validateEmail(email)) {
      toast.error(t("validation.email"));
      return;
    }

    try {
      let { status } = await postRequest(postSubscriber, { email });
      if (status === 200) {
        toast.success(t("validation.subscribed"));
        setEmail("");
      }
    } catch (err) {
      toast.error(t("validation.somethingWentWrong"));
    }
  };
 let footerIcons = [
   { icon: INSTAGRAM_ICON, to: detail?.instagram },
   { icon: TWIITER_ICON, to: detail?.twitter },
   { icon: LINKEDIN_ICON, to: detail?.linkedin },
   { icon: FACEBOOK_ICON, to: detail?.facebook },
 ];
  // let footerIconss = [
  //   { icon: LINKEDIN_ICON, to: "https://www.linkedin.com/company/athgadlang/" },
  //   { icon: INSTAGRAM_ICON, to: "https://www.instagram.com/athgadlang/" },
  //   { icon: TWIITER_ICON, to: "https://twitter.com/athGADLANG" },
  //   { icon: FACEBOOK_ICON, to: "https://www.facebook.com/athgadlang" },
  // ];

  return (
    // location.pathname !== "/" && (
    <div className={`footer_container ${width ? "custom-width" : ""}`}>
      <div className="footer_top_portion">
        <div className="footer_top_left_portion">
          <p
            className="font24_Regular"
            style={{ color: "white", marginBottom: 15 }}
          >
            {t("connectWithUs")}
          </p>
          <input
            placeholder={t("enterYourEmailAddress")}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div style={{ marginTop: 20 }}>
            <NormalButton
              size="md"
              text="Submit"
              bgColor="#FFFFFF22"
              borderColor="#FFFFFF"
              txtColor="#FFFFFF"
              customHeight={38}
              onClick={handleEmailSubmit}
            />
          </div>
        </div>
        <div className="footer_top_right_portion">
          <div className="footer_services">
            <h4
              style={{ textTransform: "capitalize", fontWeight: 100 }}
              className="font24_Regular"
            >
              {t("services")}
            </h4>
            <Link to={{ pathname: "/services/assurance", state: { level: 1 } }}>
              {t("assurance")}
            </Link>
            <Link
              to={{ pathname: "/services/accounting", state: { level: 1 } }}
            >
              {t("accounting")}
            </Link>
            <Link to={{ pathname: "/services/tax", state: { level: 1 } }}>
              {t("tax")}
            </Link>
            <Link
              to={{ pathname: "/services/consulting", state: { level: 1 } }}
            >
              {t("consulting")}
            </Link>
          </div>
          <div className="footer_quick_link">
            <h4 className="font24_Regular" style={{ fontWeight: 100 }}>
              {t("quickLinks")}
            </h4>
            <Link to="/industries">{t("industries")}</Link>
            <Link to="/insights">{t("insights")}</Link>
            <a href="https://careers.athgadlang.com/" target="_blank"  rel="noreferrer">{t("careers")}</a>
            <Link to="/webinars">{t("webinars")}</Link>
          </div>
          <div className="footer_icons footer_icons_positioning">
            {global.location === "ksa" ? (
              <img
                src={WATHIQ_LOGO}
                alt=""
                style={{ width: "85px", height: "55px", margin: "5px 5px" }}
              />
            ) : (
              <div
                style={{ width: "75px", height: "75px", margin: "10px 5px" }}
              >
                <img
                  src={ATH_LOGO}
                  alt=""
                  style={{ width: "auto", height: "70px",  }}
                />
              </div>
            )}
            <h4 className="font14_Bold" style={{ marginLeft: "-3px" }}>
              {t("findUsAt")}
            </h4>
            <div style={{ display: "flex", marginLeft: -10, marginTop: 10 }}>
              {footerIcons.map(({ icon, to }) => (
                <div
                  key={to}
                  className="icon_href_div"
                  onClick={() => window.open(to)}
                >
                  <img src={icon} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom_portion">
        <Link to="/terms-use">{t("termsOfUse")}</Link>
        <div className="separator_"></div>
        <Link to="/privacy-policy">{t("privacyPolicy")}</Link>
        <div className="separator_"></div>
        {/* <Link to="">{t("cookies")}</Link> */}
        {/* <div className="separator_"></div> */}
        <Link to="/legal-information">{t("legalInformation")}</Link>
      </div>
    </div>
  );
  // );
};


export default Footer;



// const ag = (variant, navbarTexture = "", direction) => (
//   <motion.svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="50"
//     height="100"
//     custom={direction}
//     style={{ cursor: "pointer" }}
//     viewBox="0 0 49.708 52.01"
//     variants={variant}
//   >
//     <motion.g
//       id="Final_Logo-04_1_"
//       data-name="Final Logo-04 (1)"
//       transform="translate(-7.267 -10.082)"
//     >
//       <motion.path
//         id="Path_51079"
//         data-name="Path 51079"
//         d="M67.9,26.385,50.965,12.344a9.651,9.651,0,0,0-11.319-.8,7.483,7.483,0,0,0-2.79,9.466l.559,1.2a24.918,24.918,0,0,0,6.82,8.975h0a6.112,6.112,0,0,1,0,9.412l-2.114,1.749a24.116,24.116,0,0,0-6.076,7.6v.063a7.857,7.857,0,0,0-.329,6.514,9.051,9.051,0,0,0,14.127,3.489L67.932,45.02A12.1,12.1,0,0,0,67.9,26.385Z"
//         transform="translate(-15.321)"
//         fill="#8e8e8e"
//         animate={{
//           fill: navbarTexture === "black" ? "#C9C9C9" : "#8e8e8e",
//         }}
//       />
//       <motion.path
//         id="Path_51094"
//         data-name="Path 51094"
//         d="M30.326-29.573a17.66,17.66,0,0,1-2.431.818,11.479,11.479,0,0,1-2.969.374,8.152,8.152,0,0,1-4.231-.853,4.276,4.276,0,0,1-1.847-2.443h-.14a15.328,15.328,0,0,1-1.531,1.356,8.788,8.788,0,0,1-1.578.958,11.224,11.224,0,0,1-2.116.76,12.74,12.74,0,0,1-2.84.245,6.982,6.982,0,0,1-4.629-1.6A5.253,5.253,0,0,1,4.1-34.2a7.034,7.034,0,0,1,.421-2.583A5.857,5.857,0,0,1,5.71-38.666a6.2,6.2,0,0,1,1.625-1.239,18.09,18.09,0,0,1,2.022-.912q2.1-.771,5.049-1.6a28.526,28.526,0,0,0,4.3-1.484v-2.244q0-.584-.094-1.543a4.99,4.99,0,0,0-.4-1.613,3.083,3.083,0,0,0-1.029-1.262,3.05,3.05,0,0,0-1.847-.514,4.418,4.418,0,0,0-1.718.292,5.746,5.746,0,0,0-1.11.6,9.151,9.151,0,0,0,.4,1.426,5.706,5.706,0,0,1,.327,1.847,3.11,3.11,0,0,1-.21.994,2.214,2.214,0,0,1-.608.947,4.035,4.035,0,0,1-1.2.725A6.056,6.056,0,0,1,9.17-44,4.18,4.18,0,0,1,6.2-44.943,3.233,3.233,0,0,1,5.2-47.409a3.243,3.243,0,0,1,1.04-2.349,8.508,8.508,0,0,1,2.536-1.718,14.551,14.551,0,0,1,3.39-1.087,18.092,18.092,0,0,1,3.553-.386,29.715,29.715,0,0,1,4.161.269,9.83,9.83,0,0,1,3.413,1.11,5.927,5.927,0,0,1,2.3,2.373,8.4,8.4,0,0,1,.853,4.056q0,2.642-.105,6.265t-.105,4.769a3.637,3.637,0,0,0,.316,1.66,2.01,2.01,0,0,0,.97.912,3.451,3.451,0,0,0,1.251.222q.853.035,1.555.035ZM18.707-41.752q-1.309.444-2.595,1.017a10.357,10.357,0,0,0-2.1,1.2,4.529,4.529,0,0,0-1.356,1.671,5.457,5.457,0,0,0-.491,2.4,3.59,3.59,0,0,0,.83,2.642A2.8,2.8,0,0,0,15.037-32a3.888,3.888,0,0,0,2.116-.526,6.091,6.091,0,0,0,1.461-1.3Z"
//         transform="translate(3.17 76.662)"
//         animate={{
//           fill: navbarTexture === "black" ? "#143557" : "#fff",
//         }}
//       />
//       <motion.path
//         id="Path_51080"
//         data-name="Path 51080"
//         d="M51,52.86v-.266c0-7.307,5.36-12.234,12.567-12.234,5.666,0,10.692,2.578,11.161,8.812H67.944c-.338-2.254-1.776-3.417-4.327-3.417-3.385,0-5.409,2.614-5.409,6.937v.266c0,3.89,1.442,6.906,5.6,6.906,3.349,0,4.625-1.708,4.76-3.606H64.072V51.467H75.341v2.948c0,6.1-3.989,10.692-11.72,10.692C55.44,65.093,51,60.031,51,52.86Z"
//         transform="translate(-24.02 -16.63)"
//         fill="#a71f25"
//       />
//     </motion.g>
//   </motion.svg>
// );