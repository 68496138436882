import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useState } from "react";

let pathVariant = {
  stroke: "#fff",
  transition: {
    stroke: {
      delay: 0.5,
    },
  },
};

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    strokeLinecap="round"
    animate={
      props.isOpen
        ? { stroke: "#000" }
        : props.hamBurgerColor === "black"
        ? { stroke: "#000", ...props.variants.closed }
        : { ...pathVariant, ...props.variants.closed }
    }
    {...props}
  />
);

export const MenuToggle = ({ toggle, hamBurgerColor, toggleValue, isOpen }) => {
  const controls = useAnimation();
  const { t } = useTranslation();
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (toggleValue) {
      controls.start("open");
    } else {
      controls.start("closed");
    }
  }, [toggleValue]);
  useEffect(()=>{
    setWidth(window.innerWidth );
  },[])

  return (
    <motion.button animate={controls} onClick={toggle} className="hamburger">
      <div style={{ display: "flex", alignItems: "center" }}>
        <svg width="32" height="23" viewBox="0 0 23 23">
          <Path
            isOpen={toggleValue}
            hamBurgerColor={hamBurgerColor}
            variants={{
              closed: {
                d: "M 2 1.5 L 32 1.5",
              },
              open: {
                d: "M 3 16.5 L 17 2.5",
                stroke: "black",
              },
            }}
          />
          <Path
            isOpen={toggleValue}
            hamBurgerColor={hamBurgerColor}
            d="M 2 9.423 L 23 9.423"
            variants={{
              closed: {
                opacity: 1,
              },
              open: {
                opacity: 0,
              },
            }}
            transition={{ duration: 0.1 }}
          />
          <Path
            isOpen={toggleValue}
            hamBurgerColor={hamBurgerColor}
            variants={{
              closed: {
                d: "M 2 17.346 L 14 17.346",
              },
              open: {
                d: "M 3 2.5 L 17 16.346",
              },
            }}
          />
        </svg>
        <span
          style={{
            color: hamBurgerColor === "black" ? "black" : "white",
            visibility: toggleValue ? "hidden" : "visible",
          }}
        >
          {width>720&&t("menu")}
        </span>
      </div>
    </motion.button>
  );
};
