import { Badge } from "@material-ui/core";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  setLanguage,
  setLayoutDirection,
  setLocation,
} from "../../../redux/global/global.actions";
import {
  getLanguage,
  languages,
  locations,
  getRegion,
  setPageDirection,
} from "../../../utils";
import { IconButton } from "../../button";
import useWindowDimensions from "../../dimensions";
import { DropDownComponent } from "../../DropDownComponent";
import { useOnClickOutside } from "../../useOutsideHook";
import "./index.css";

const NavbarActions = ({ onSearchBtnClick, parent = "navbar" }) => {
  let { width: fullWidth } = useWindowDimensions();
  const { i18n } = useTranslation();
  const global = useSelector((state) => state.global);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let lang = getLanguage();
    let location = getRegion();
    i18n.changeLanguage(lang);
    setPageDirection(lang);
    dispatch(setLanguage(lang));
    dispatch(setLocation(location));
    dispatch(setLayoutDirection(lang === "en" ? "ltr" : "rtl"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = (value) => {
    dispatch(setLayoutDirection(value === "en" ? "ltr" : "rtl"));
    setPageDirection(value);
    let storage = localStorage.getItem("athGadlang")
      ? JSON.parse(localStorage.getItem("athGadlang"))
      : {};
    localStorage.setItem(
      "athGadlang",
      JSON.stringify({ ...storage, language: value })
    );

    i18n.changeLanguage(value);
    dispatch(setLanguage(value));
  };

  const handleLocationChange = (value) => {
    let storage = localStorage.getItem("athGadlang")
      ? JSON.parse(localStorage.getItem("athGadlang"))
      : {};
    localStorage.setItem(
      "athGadlang",
      JSON.stringify({ ...storage, region: value })
    );
    dispatch(setLocation(value));
  };

  const getIntialSelectedLanguage = () => {
    let languagesCopy = languages.filter(
      (item) => item.value !== global.language
    );
    languagesCopy.unshift(languages.find((i) => i.value === global.language));
    return languagesCopy;
  };

  const getIntialSelectedLocation = () => {
    let locationsCopy = locations.filter(
      (item) => item.value !== global.location
    );

    locationsCopy.unshift(locations.find((i) => i.value === global.location));
    return locationsCopy;
  };

  const getLanguageDD = () => (
    <DropDownComponent
      key={global.language}
      style={{
        width: "fit-content",
      }}
      texture={
        global.navbarProps.navbarContentTexture === "black" ? "black" : "white"
      }
      dropdownType="location"
      prependIcon={false}
      dropdownContentArray={[...getIntialSelectedLanguage()]}
      onSelect={changeLanguage}
    />
  );


  const getLocationDD = () => {
      return(
        <>
      <DropDownComponent
        texture={
          global.navbarProps.navbarContentTexture === "black" ? "black" : "white"
        }
        dropdownType="location"
        dropdownContentArray={getIntialSelectedLocation()}
        onSelect={handleLocationChange}
      />
        </>
      );
    
    };

  const getSearchIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20.914}
      height={19}
      style={{
        cursor: "pointer",
      }}
      onClick={onSearchBtnClick}
    >
      <motion.g
        animate={{
          stroke:
            global.navbarProps.navbarContentTexture === "black"
              ? "#000"
              : "#fff",
        }}
        strokeWidth={2}
        fill="none"
      >
        <g>
          <circle cx={8} cy={8} r={8} stroke="none" />
          <circle cx={8} cy={8} r={7} />
        </g>
        <path strokeLinecap="round" d="M13.5 13.5l6 6" />
      </motion.g>
    </svg>
  );

  const getSignupBtn = () => (
    <IconButton
      borderColor={"red"}
      size="sm"
      onClick={() => history.push("/login-signup")}
      txt="sign up"
      bgColor={`var(--primary-color)`}
      txtColor="#FFFFFF"
      fontSize="14-Regular"
    />
  );

  const getConditionalView = () => {
    if (parent === "navbar" && fullWidth > 1000) {
      return (
        <div className="navbar_actions">
          {getLanguageDD()}
          {getLocationDD()}
          {
            <NotificationBell
              navbarTexture={global.navbarProps.navbarContentTexture}
            />
          }
          {location.pathname !== "/search" && (
            <span style={{ marginTop: 7 }}>{getSearchIcon()}</span>
          )}
          {location.pathname !== "/login-signup" && getSignupBtn()}
        </div>
      );
    } else if (parent === "navbar" && fullWidth <= 1000 && fullWidth > 680) {
      return (
        <div className="navbar_actions">
          {location.pathname !== "/search" && getSearchIcon()}
          {location.pathname !== "/login-signup" && getSignupBtn()}
        </div>
      );
    } else if (parent === "navbar" && fullWidth <= 680) {
      return (
        <div className="navbar_actions">
          {location.pathname !== "/search" && getSearchIcon()}
        </div>
      );
    } else if (parent === "sidebar" && fullWidth <= 1000 && fullWidth > 680) {
      return (
        <div className="sidebar_actions">
          {getLanguageDD()}
          {getLocationDD()}
          {
            <NotificationBell
              navbarTexture={global.navbarProps.navbarContentTexture}
            />
          }
        </div>
      );
    } else if (parent === "sidebar" && fullWidth <= 1000 && fullWidth > 450) {
      return (
        <div className="sidebar_actions">
          {getLanguageDD()}
          {getLocationDD()}
          {location.pathname !== "/login-signup" && getSignupBtn(0)}
          {
            <NotificationBell
              navbarTexture={global.navbarProps.navbarContentTexture}
            />
          }
        </div>
      );
    } else if (parent === "mobileSidebar" && fullWidth <= 450) {
      return (
        <div className="mobileSidebar_actions">
          {location.pathname !== "/login-signup" && getSignupBtn(10)}
          {
            <NotificationBell
              navbarTexture={global.navbarProps.navbarContentTexture}
            />
          }
          {getLanguageDD()}
          {getLocationDD()}
        </div>
      );
    } else {
      return <></>;
    }
  };
  return getConditionalView();
};

const NotificationBell = ({ navbarTexture = "" }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const containerRef = useRef();
  useOnClickOutside(containerRef, () => setIsPanelOpen(false));
  const [isOpened, setIsOpened] = useState(false);
  const { language } = useSelector((state) => state.global);
  return (
    <div ref={containerRef} className="notification_bell">
      <Badge color="secondary" badgeContent={isOpened ? 0 : 1}>
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.181"
          height="20.625"
          viewBox="0 0 19.181 20.625"
          onClick={() => {
            setIsPanelOpen((currentVal) => !currentVal);
            setIsOpened(true);
          }}
        >
          <g id="icon_notification" transform="translate(-6.775 -3.93)">
            <motion.path
              id="Path_51109"
              data-name="Path 51109"
              d="M19.539,28.336a.777.777,0,0,0-.762.612,1.5,1.5,0,0,1-.3.654,1.134,1.134,0,0,1-.966.354,1.153,1.153,0,0,1-.966-.354,1.5,1.5,0,0,1-.3-.654.777.777,0,0,0-.762-.612h0a.782.782,0,0,0-.762.954,2.681,2.681,0,0,0,2.79,2.226A2.676,2.676,0,0,0,20.3,29.29a.785.785,0,0,0-.762-.954Z"
              transform="translate(-1.162 -6.96)"
              fill="#fff"
              animate={{ fill: navbarTexture === "black" ? "#000" : "#fff" }}
            />
            <motion.path
              id="Path_51110"
              data-name="Path 51110"
              d="M25.715,18.721c-.924-1.013-2.742-1.607-2.742-6.145,0-4.657-2.472-6.529-4.776-6.978-.216-.045-.372-.1-.372-.295V5.158a1.359,1.359,0,0,0-1.44-1.228h-.036a1.359,1.359,0,0,0-1.44,1.228V5.3c0,.185-.156.25-.372.295-2.31.454-4.776,2.321-4.776,6.978,0,4.537-1.818,5.126-2.742,6.145-.594.654-.03,1.587.954,1.587H24.779C25.745,20.308,26.3,19.37,25.715,18.721Zm-2.34.29H9.382c-.228,0-.348-.22-.2-.364a6.034,6.034,0,0,0,1.26-1.667,9.624,9.624,0,0,0,.858-4.4,6.676,6.676,0,0,1,1.254-4.333,3.94,3.94,0,0,1,2.322-1.378,2.232,2.232,0,0,0,1.116-.524.54.54,0,0,1,.714-.01,2.314,2.314,0,0,0,1.128.534,3.94,3.94,0,0,1,2.322,1.378,6.676,6.676,0,0,1,1.254,4.333,9.624,9.624,0,0,0,.858,4.4,6.116,6.116,0,0,0,1.29,1.692C23.693,18.806,23.585,19.01,23.375,19.01Z"
              transform="translate(0 0)"
              fill="#fff"
              animate={{ fill: navbarTexture === "black" ? "#000" : "#fff" }}
            />
          </g>
        </motion.svg>
      </Badge>
      <AnimatePresence>
        {isPanelOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="notification_list"
          >
            {language === "en"
              ? notificationsArray.map((notification, key) => (
                  <Notification {...notification} key={key} />
                ))
              : notificationArrayAr.map((notification, key) => (
                  <Notification {...notification} key={key} />
                ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const notificationsArray = [
  {
    heading: `See our latest webinar`,
    paragraph: `Signup for our free webinar "Regulatory Compliance for E-commerce in MENA" happening January 13th, 2022 at 3:00 pm (GMT +4) proudly presented in co-operation with Payoneer`,
    to: "https://register.payoneer.com/en/webinar/regulatory-compliance/?utm_source=ATHGadlang&utm_medium=partner&utm_campaign=webinar&aff_id=42104&offer_id=399",
  },
];

const notificationArrayAr = [
  {
    heading: `مشاهدة آخر جلسة المحادثة الإلكترونية`,
    paragraph: `سجل الدخول إلى جلسة المحادثة الإلكترونية "المستجدات المحاسبية للتجارة الإلكترونية في المناطق الجنوبية الأوروبية" يوم 13 يناير 2022 على الساعة 3:00 مساء (GMT +4) بحسن الإحتفاظ مع Payoneer`,
    to: "https://register.payoneer.com/en/webinar/regulatory-compliance/?utm_source=ATHGadlang&utm_medium=partner&utm_campaign=webinar&aff_id=42104&offer_id=399",
  },
];

const Notification = ({ heading, paragraph, to }) => (
  <div className="notification" onClick={() => window.open(to)}>
    <h5>{heading}</h5>
    <p>{paragraph}</p>
  </div>
);

export default NavbarActions;
