import { Avatar } from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import THIN_ARROW from '../../assets/dropdown-arrow.svg';
import { setLocation } from '../../redux/global/global.actions';
import { useOnClickOutside } from '../useOutsideHook';
import './index.css';

export const DropDownComponent = ({
	dropdownType = 'presenter',
	placeHolder = '',
	onSelect = () => {},
	texture = '',
	dropdownContentArray = [],
	arrowType = 'thin',
	prependIcon = true,
	style = null,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
	const [selectedValue, setSelectedValue] = useState(dropdownContentArray[0]);
	const { t } = useTranslation();
	const layoutDirection = useSelector((state) => state.global.layoutDirection);

	useEffect(() => {
		setSelectedValue(dropdownContentArray[0]);
	}, [dropdownContentArray]);

	useOnClickOutside(dropdownRef, (e) => {
		setIsOpen(false);
	});

	const camalize = (str) => {
		return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
	};

	return dropdownType === 'presenter' ? (
		<motion.div
			ref={dropdownRef}
			className="dropdown_component"
			onClick={(e) => {
				e.stopPropagation();
				setIsOpen((prevValue) => !prevValue);
			}}
		>
			<span
				className="text_decoration"
				style={{
					color: `var(--secondary-color)`,
					fontSize: 12,
					cursor: 'pointer',
				}}
			>
				{t(camalize(placeHolder))}
			</span>
			<span
				style={{
					marginLeft: layoutDirection === 'ltr' ? 10 : 0,
					marginRight: layoutDirection === 'rtl' ? 10 : 0,
				}}
			>
				<motion.img animate={{ rotate: isOpen ? 180 : 0 }} src={arrowType === 'thin' ? THIN_ARROW : null} width={10} height={10} />
			</span>
			<AnimatePresence>
				{isOpen && (
					<motion.div
						className="dropdown_content presenter_dd"
						style={{ ...style, width: 'fit-content' }}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						{dropdownContentArray.length > 0 ? (
							dropdownContentArray.map((item, index) => (
								<div
									key={index}
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'start',
										marginTop: 10,
										gap: 8,
									}}
								>
									<Avatar
										alt="Remy Sharp"
										src={item?.img}
										style={{
											width: 30,
											height: 30,
										}}
									/>

									<span style={{ marginTop: 8 }}>{item.label}</span>
								</div>
							))
						) : (
							<p style={{ marginLeft: 15 }}>No Presenters</p>
						)}
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	) : (
		<motion.div
			ref={dropdownRef}
			style={{
				...style,
				// boxShadow: isOpen
				//   ? `3px 7px 8px 0px rgba(${0}, ${0}, ${0}, ${0.16})`
				//   : "",
			}}
			className={texture === 'black' ? 'dropdown_component black' : 'dropdown_component white'}
			onClick={(e) => {
				e.stopPropagation();
				setIsOpen((prevValue) => !prevValue);
			}}
		>
			{prependIcon && (
				<motion.svg style={{ marginRight: 10, marginBottom: 10 }} xmlns="http://www.w3.org/2000/svg" width={15} height={21.333}>
					<g data-name="Group 5917">
						<g data-name="Group 5916">
							<path
								data-name="Path 2903"
								d="M7.5 0a7.5 7.5 0 00-6.38 11.444l5.954 9.594a.625.625 0 00.531.3.625.625 0 00.531-.3l5.8-9.687A7.5 7.5 0 007.5 0zm5.369 10.7l-5.275 8.807-5.413-8.723a6.254 6.254 0 1110.688-.084z"
								fill="#191919"
							/>
						</g>
					</g>
					<g data-name="Group 5919">
						<g data-name="Group 5918">
							<path
								data-name="Path 2904"
								d="M7.5 3.75a3.75 3.75 0 103.75 3.75A3.754 3.754 0 007.5 3.75zm0 6.258A2.508 2.508 0 1110 7.5a2.51 2.51 0 01-2.5 2.508z"
								fill="#191919"
							/>
						</g>
					</g>
				</motion.svg>
			)}
			<span
				style={{
					color: texture === 'black' ? '#191919' : '#ffff',
					fontSize: 14,
					cursor: 'pointer',
					marginLeft: layoutDirection === 'ltr' ? (prependIcon ? 4 : 0) : 10,
					marginRight: layoutDirection === 'rtl' ? (prependIcon ? 8 : 10) : 10,
				}}
			>
				{selectedValue?.label}
			</span>
			<motion.svg xmlns="http://www.w3.org/2000/svg" animate={{ rotate: isOpen ? 180 : 0 }} width={10} height={10}>
				<g data-name="Group 5820">
					<path data-name="Icon ionic-md-arrow-dropdown" d="M0 0l4.511 4.511L9.022 0z" fill="#191919" />
				</g>
			</motion.svg>

			<AnimatePresence>
				{isOpen && (
					<motion.div
						className="dropdown_content location_dd"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						style={{
							marginRight: '-22px',
						}}
					>
						{dropdownContentArray.map((item, index) => (
							<div
								key={index}
								style={{
									height: 30,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'start',
									cursor: 'pointer',
									paddingLeft: layoutDirection === 'ltr' ? 16 : 0,
									paddingRight: layoutDirection === 'rtl' ? 16 : 0,
								}}
								onClick={() => {
									setSelectedValue(dropdownContentArray[index]);
									onSelect(item.value);
									if (item?.value === 'ksa') {
										setLocation(item?.value);
										localStorage.setItem('athGadlang',`{"region":"${item?.value}"}`)
										window.location.href = 'https://wathiqconsultancy.com/';
									} else {
										setLocation(item?.value);
										if (window.location.href === 'https://wathiqconsultancy.com/') {
											setTimeout(() => {
												window.location.href = 'https://athgadlang.com/?region=' + item?.value;
											}, [200]);
										} else {
											const urlParams = new URLSearchParams(window.location.search);
											const region = urlParams.get('region');
											if (region) {
												let url = window.location.origin + window.location.pathname + '?region=' + item?.value;
												window.location.href = url;
											}
										}
									}
								}}
							>
								<span style={{ marginTop: 8, color: '#191919' }}>{item.label}</span>
							</div>
						))}
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	);
};
