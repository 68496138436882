import { CircularProgress } from "@material-ui/core";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getRequest } from "../../../../service/apiClient";
import { getSearchResult } from "../../../../service/constants";
import { generateKey, truncateString } from "../../../../utils";
import { NormalButton } from "../../../normalButton";
import useDebounce from "../../../useDebounce";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./index.css";

export const SearchBar = ({ onHideSearchbar, isOpen }) => {
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [results, setResults] = useState(undefined);
  const history = useHistory();
  const { t } = useTranslation();
  const { layoutDirection, language, location } = useSelector(
    (state) => state.global
  );

  useEffect(() => {
    const init = async () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        try {
          let { data, status } = await getRequest(
            `${getSearchResult}?_q=${debouncedSearchTerm}&&_limit=5&&_start=0&&insights=1&&webinars=1&&industries=1&&services=1`
          );
          setIsSearching(false);
          if (status === 200) {
            setResults(data);
          }
        } catch (err) {}
      } else {
        setResults([]);
        setIsSearching(false);
      }
    };
    init();
  }, [debouncedSearchTerm, language, location]);

  const CrossIcon = () => (
    <svg
      onClick={() => {
        setSearchText("");
        onHideSearchbar();
      }}
      style={{ cursor: "pointer", position: "absolute" }}
      height="311pt"
      viewBox="0 0 311 311.07733"
      width="19px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0" />
      <path d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0" />
    </svg>
  );

  const searchbarVariant = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };

  const searchBarResultVariant = {
    hidden: {
      height: 0,
      opacity: 0,
    },
    show: {
      height: 300,
      opacity: 1,
      transition: { easings: "easeInOut" },
    },
    exit: {
      opacity: 0,
      height: 0,
    },
  };

  return (
    <>
      <motion.div
        className="searchbar_container"
        variants={searchbarVariant}
        initial={"hidden"}
        animate={"show"}
        exit={"exit"}
      >
        <CrossIcon />
        <div className="search_bar">
          {" "}
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={t("searchInTheFollowingFields")}
          />
          {isSearching && (
            <div
              style={{
                position: "absolute",
                right: layoutDirection === "ltr" ? 0 : "unset",
                left : layoutDirection === "rtl" ? 0 : "unset",
                top: 0,
              }}
            >
              {" "}
              <CircularProgress size={20} style={{ color: "white" }} />
            </div>
          )}
        </div>
      </motion.div>
      <AnimatePresence>
        {searchText && (
          <motion.div
            variants={searchBarResultVariant}
            initial={"hidden"}
            animate={"show"}
            exit={"exit"}
            className="search_result_container"
          >
            <motion.div className="after_search_container">
              <span style={{ color: "white" }}>{t("showingResultsFor")}</span>
              <span style={{ color: "white" }} className="font16_Bold">
                "{searchText}"
              </span>
              {results && (
                <div className="results_div">
                  {Object.keys(results).map((item) => {
                    return (
                      <Fragment key={generateKey(item)}>
                        {results[item].length > 0 && (
                          <h3
                            style={{
                              color: "white",
                              textTransform: "capitalize",
                              cursor: "pointer",
                            }}
                            className="font16_Bold"
                            onClick={() => {
                              history.push(`/${item}`);
                              setSearchText("");
                              onHideSearchbar();
                            }}
                          >
                            {item}
                          </h3>
                        )}
                        <div style={{ color: "white" }}>
                          {item !== "insights" &&
                            item !== "totalCount" &&
                            results[item].map((obj) => (
                              <p
                                key={generateKey(obj?.title)}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  history.push(`/${item}/${obj?.id}`, {
                                    level: obj?.level || 1,
                                  });
                                  setSearchText("");
                                  onHideSearchbar();
                                }}
                              >
                                {obj?.title}
                              </p>
                            ))}
                        </div>
                        {item === "insights" && (
                          <InsightResultView content={results[item]} />
                        )}
                      </Fragment>
                    );
                  })}
                  <div id="gradient"></div>
                </div>
              )}
            </motion.div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "15%",
              }}
            >
              <NormalButton
                onClick={() => {
                  setSearchText("");
                  onHideSearchbar();
                  history.push("/search", { keyword: searchText });
                }}
                text={"See All Results"}
                borderColor="white"
                bgColor="transparent"
                borderRadius={0}
                fontSize={16}
                arrow={true}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const InsightResultView = ({ content }) =>
  content.map((item) => (
    <div key={generateKey(item?.title)} style={{ marginBottom: 15 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4 className="font14_Regular">{item.title}</h4>
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <p className="font14_Regular" style={{ textAlign: "right" }}>
            {item.userName || "N/A"}
          </p>{" "}
          <span style={{ marginLeft: 10, marginRight: 10 }}>|</span>{" "}
          <p className="font14_Regular">
            {moment(item.createdAt).format("MM/DD/YYYY")}
          </p>
        </div>
      </div>
      <p className="font14_Regular">
        {truncateString(item.insightContent, 100)}
      </p>
    </div>
  ));
