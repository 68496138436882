import DOWN_ARROW from "../../assets/icon_chevron_down.svg";

import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const SingleLevel = ({ item, hideSidebar }) => {
  return (
    <ListItem button>
      <ListItemText
        primary={
          <Link
            onClick={hideSidebar}
            to={{
              pathname: item.to,
              state: {
                level: item?.level,
                id: item.id,
                categoryName: item?.title,
              },
            }}
            style={{ color: "#2B2B2B", fontSize: 16, textDecoration: "none" }}
          >
            {item.title}
          </Link>
        }
      />
    </ListItem>
  );
};

const MultiLevel = ({ item, hideSidebar }) => {
  const { subMenu: children } = item;
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem button>
        <ListItemText
          
          primary={
            <Link
              onClick={hideSidebar}
              to={{
                pathname: item.to,
                state: {
                  level: item?.level,
                  id: item.id,
                  categoryName: item?.title,
                },
              }}
              style={{
                color: "#2B2B2B",
                textDecoration: "none",
                fontSize: 16,
                fontFamily: open ? "Myanmer-Bold" : "",
              }}
            >
              {item.title}
            </Link>
          }
        />
        {
          <img
            alt=""
            src={DOWN_ARROW}
            animate={{ rotate: open ? 180 : 0 }}
            onClick={handleClick}
          />
        }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children &&
            children.map((child, key) => (
              <MenuItem
                key={key}
                id={key}
                item={{ ...child, onLinkClick: hideSidebar }}
              />
            ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

const MenuItem = ({ item,id,key }) => { 
  const Component = item.isMenuExist ? MultiLevel : SingleLevel;
  return <Component item={item} hideSidebar={item.onLinkClick} id={id}  />;
};

export { MenuItem, MultiLevel, SingleLevel };
