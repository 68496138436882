import React from "react";

const HOMEPAGE = React.lazy(() => import("../pages/home"));
const ALLSERVICEPAGE = React.lazy(() => import("../pages/service-page"));
const SERVICEDETAILPAGE = React.lazy(() =>
  import("../pages/service-detail-page")
);
const INSIGHTPAGE = React.lazy(() => import("../pages/insight-page"));
const PRIVACYPOLICY = React.lazy(() => import("../pages/privacy-policy"));
const LEGALINFO = React.lazy(() => import("../pages/legal-information"));
const TERMSUSE = React.lazy(() => import("../pages/terms-use"));

const INISGHTREADPAGE = React.lazy(() => import("../pages/insight-read-page"));

const SEARCHPAGE = React.lazy(() => import("../pages/search-page"));

const INDUSTRYPAGE = React.lazy(() => import("../pages/industry-page"));

const INDUSTRYDETAILPAGE = React.lazy(() =>
  import("../pages/industry-detail-page")
);

const WEBINARPAGE = React.lazy(() => import("../pages/webinar-page"));

const CAREERSPAGE = React.lazy(() => import("../pages/careers-page"));

const SEARCHCAREERPAGE = React.lazy(() =>
  import("../pages/search-careers-page")
);

const CAREERDETAIL = React.lazy(() => import("../pages/career-detail"));

const APPLYJOB = React.lazy(() => import("../pages/apply-job"));

const ALLCLIENTPAGE = React.lazy(() => import("../pages/all-clients-page"));

const WEBINARDETAIL = React.lazy(() => import("../pages/webinar-detail-page"));
const LOGINSIGNUPPAGE = React.lazy(() => import("../pages/login-signup-page"));

const CONTACTUSSCEEN = React.lazy(() => import("../pages/contact-us-page"));

const PROFILEDETAIL = React.lazy(() => import("../pages/profile-detail-page"));

const Routes = [
  {
    path: "/",
    Component: HOMEPAGE,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/services",
    Component: ALLSERVICEPAGE,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/services/:sid",
    Component: SERVICEDETAILPAGE,
    exact: true,
    navbarTexture: "white",
  },
  {
    path: "/insights",
    Component: INSIGHTPAGE,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/privacy-policy",
    Component: PRIVACYPOLICY,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/terms-use",
    Component: TERMSUSE,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/legal-information",
    Component: LEGALINFO,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/insights/:iid",
    Component: INISGHTREADPAGE,
    exact: true,
    navbarTexture: "black",
  },

  {
    path: "/search",
    Component: SEARCHPAGE,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/industries",
    Component: INDUSTRYPAGE,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/industries/:iid",
    Component: INDUSTRYDETAILPAGE,
    exact: true,
    navbarTexture: "white",
  },
  {
    path: "/webinars",
    Component: WEBINARPAGE,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/webinars/:wid",
    Component: WEBINARDETAIL,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/careers",
    Component: CAREERSPAGE,
    exact: true,
    navbarTexture: "white",
  },
  {
    path: "/search-careers",
    Component: SEARCHCAREERPAGE,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/career-detail",
    Component: CAREERDETAIL,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/apply-job",
    Component: APPLYJOB,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/clients",
    Component: ALLCLIENTPAGE,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/login-signup",
    Component: LOGINSIGNUPPAGE,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/contact-us",
    Component: CONTACTUSSCEEN,
    exact: true,
    navbarTexture: "black",
  },
  {
    path: "/profile/:teamId",
    Component: PROFILEDETAIL,
    exact: true,
    navbarTexture: "black",
  },
];

export default Routes;
