let getIndustries = "industries";
let getTestimonials = "testimonials";
let getClients = "clients";
let getServices = "services";
let getServicesList = "service/list";
let getInsights = "insights";
let getWebinars = "webinars";
let getWebinarFilterListing = "fetch/webinars";
let getWebinarListing = "webinar-categories";
let getTeams = "teams";
let getMenu = "menu";
let getCompany = "home-pages";
let getSearchResult = "search";
let getLocations = "office-locations";
let getInsightsListing = "fetch/insights";
let getIndustriesContent = "fetch/industries";
let getIndustry = "industries";
let getCareers = "careers";
let getJobs = "jobs";
let getCategories = "categories";
let postJob = "apply-jobs";
let postContactInfo = "contact-uses";
let getReviews = "reviews";
let postSubscriber = 'subscribers';
let contactDetail = "contact-details";
let numbers = 'numbers';
let privacyPolicy = "privacy-policies"
let legalInfo = "legal-informations";
let termsUSe = "terms-of-uses";

export {
  getIndustries,
  getTestimonials,
  getClients,
  legalInfo,
  termsUSe,
  privacyPolicy,
  contactDetail,
  numbers,
  getServices,
  getServicesList,
  getInsights,
  getWebinars,
  getTeams,
  getMenu,
  getCompany,
  getSearchResult,
  getLocations,
  getInsightsListing,
  getIndustriesContent,
  getIndustry,
  getWebinarListing,
  getWebinarFilterListing,
  getCareers,
  getJobs,
  getCategories,
  postJob,
  postContactInfo,
  getReviews,
  postSubscriber
};
