const SET_NAVBAR_PROPS = "SET_NAVBAR_PROPS";
const SET_SUB_MENU = "SET_SUB_MENU";
const SET_ACTIVE_URL = "SET_ACTIVE_URL";
const SET_SECTION_IN_VIEW_INDEX = "SET_SECTION_IN_VIEW_INDEX";
const SET_LOCATION = "SET_LOCATION";
const SET_LANGUAGE = "SET_LANGUAGE";
const SET_LAYOUT_DIRECTION = 'SET_LAYOUT_DIRECTION'
const SET_LOADING = "SET_LOADING";

export {
  SET_NAVBAR_PROPS,
  SET_SUB_MENU,
  SET_ACTIVE_URL,
  SET_SECTION_IN_VIEW_INDEX,
  SET_LOCATION,
  SET_LANGUAGE,
  SET_LAYOUT_DIRECTION,
  SET_LOADING,
};
